import { Text } from "@chakra-ui/react";
import React from "react";

function SectionHeader({ text, ...rest }) {
  return (
    <Text
      fontSize="4xl"
      color="brand.theme"
      fontWeight={900}
      mb={{ base: 4, md: 6 }}
      {...rest}
    >
      {text}
    </Text>
  );
}

export default SectionHeader;
