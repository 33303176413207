import React from "react";
import "./Footer.css";
import SectionHeader from "../SectionHeader";

export const Footer = () => {
  return (
    <footer className="container footer">
      <SectionHeader text="Follow Us!" />
      <nav className="footer-navigation">
        <div className="btnholderinner icons">
          <a
            href="https://t.me/shakinu"
            target="_blank"
            className="roundbuttton margin w-inline-block"
            rel="noopener noreferrer"

          >
            <img
              src="https://uploads-ssl.webflow.com/60b8f056bc23642aba1762f3/6103d97637327f8932e33b98_tg.svg"
              loading="lazy"
              alt=""
            />
          </a>
          <a
            href="https://www.twitter.com/shakitainu"
            target="_blank"
            className="roundbuttton margin w-inline-block"
            rel="noopener noreferrer"

          >
            <img
              src="https://uploads-ssl.webflow.com/60b8f056bc23642aba1762f3/6103d9762515668c9e96a3b9_twitter.svg"
              loading="lazy"
              alt=""
            />
          </a>
          <a
            href="https://poocoin.app/tokens/0x76e08e1c693d42551dd6ba7c2a659f74ff5ba261"
            target="_blank"
            className="roundbuttton margin w-inline-block"
            rel="noopener noreferrer"

          >
            <img
              src="https://uploads-ssl.webflow.com/60b8f056bc23642aba1762f3/6103d976e63b63acc3d64dcd_poocoin.svg"
              loading="lazy"
              alt=""
            />
          </a>
          <a
            href="https://reddit.com/r/ShakitaInu/"
            target="_blank"
            rel="noopener noreferrer"
            className="roundbuttton margin w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/60b8f056bc23642aba1762f3/6192cd39d927ba6848645ea4_Vector-1.svg"
              loading="lazy"
              alt=""
            />
          </a>
          <a
            href="https://www.instagram.com/shakitainu"
            target="_blank"
            className="roundbuttton margin w-inline-block"
            rel="noopener noreferrer"

          >
            <img
              src="https://uploads-ssl.webflow.com/60b8f056bc23642aba1762f3/6192cd3b1db3bb5854405893_Vector.svg"
              loading="lazy"
              alt=""
            />
          </a>
          {/*<a*/}
          {/*  href="https://dex.guru/token/0x76e08e1c693d42551dd6ba7c2a659f74ff5ba261-bsc"*/}
          {/*  target="_blank"*/}
          {/*  className="roundbuttton margin w-inline-block"*/}
          {/*>*/}
          {/*  <img*/}
          {/*    src="https://uploads-ssl.webflow.com/60b8f056bc23642aba1762f3/6103d97620e59b4bc45c6400_dexguru.png"*/}
          {/*    loading="lazy"*/}
          {/*    alt=""*/}
          {/*  />*/}
          {/*</a>*/}
          {/*<a*/}
          {/*  href="https://bscscan.com/token/0x76e08e1c693d42551dd6ba7c2a659f74ff5ba261"*/}
          {/*  target="_blank"*/}
          {/*  className="roundbuttton w-inline-block"*/}
          {/*>*/}
          {/*  <img*/}
          {/*    src="https://uploads-ssl.webflow.com/60b8f056bc23642aba1762f3/6103d9760eac216be31e3861_bscscan.png"*/}
          {/*    loading="lazy"*/}
          {/*    alt=""*/}
          {/*  />*/}
          {/*</a>*/}
        </div>
      </nav>
    </footer>
  );
};
