import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react";
import wallet from "../../assets/logos/wallet.svg";
import connected from "../../assets/logos/connected.svg";
import { shortenHex } from "../../util";

export const AccountV2 = ({ purple }) => {
  // 4. Use modal hook
  const { open } = useWeb3Modal();
  const { isConnected, chainId, address } = useWeb3ModalAccount();

  return (
    <>
      <button
        onClick={() => open()}
        className={purple ? "btn purple-button" : "btn connect-button"}
      >
        {isConnected ? (
          <>
            <img
              alt="unconnet_logo"
              src={connected}
              className="padding-right-8"
            />
            {shortenHex(address, 4)}
          </>
        ) : (
          <>
            <img alt="wallet_logo" src={wallet} className="padding-right-8" />
            Connect to Wallet
          </>
        )}
      </button>
    </>
  );
};
