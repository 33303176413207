import React from "react";
import Marquee from "react-fast-marquee";

import { IMAGES } from "../../helpers/images";
import "./Slider.css";

export const Slider = () => {
  return (
    <div
      className="slider"
      style={{
        width: "100%",
        whiteSpace: "nowrap",
      }}
    >
      <Marquee direction="right" gradient={false} childMargin={4} speed={70}>
        {IMAGES.map((item, index) => (
          <img
            key={item}
            alt=""
            src={item}
            width="216"
            height="216"
            style={{
              marginRight: index === IMAGES.length - 1 ? 0 : "8px",
              borderRadius: "8px",
            }}
          />
        ))}
      </Marquee>
    </div>
  );
};
