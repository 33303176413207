import React, { useEffect, useState } from "react";
import "./GiveAwaySection.css";
import { Slider } from "../Slider";
import { Element } from "react-scroll";
import { AccountV2 as Account } from "../Account";
import { ContractContext } from "../../context/ContractContext";
import {
  NETWORK_NAME,
  MINTING_STARTED,
  NETWORK_ID,
  MINT_PRICE_IN_ETH,
} from "../../consts";
import { useStepper } from "use-stepper";
import SectionHeader from "../SectionHeader";
import { Modal, Button } from "react-bootstrap";
import { useWalletInfo } from "@web3modal/ethers/react";
const min = 1;
const defaultValue = 1;
const max = 20;

export const GiveAwaySection = () => {
  // const [count, setCount] = React.useState(1);
  const [show, setShow] = useState(false);
  const [heading, setHeading] = useState(false);
  const [body, setBody] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { walletInfo } = useWalletInfo();

  const [totalShak, setShak] = React.useState("0");
  const [totalMinted, setMinted] = React.useState("0");

  const { account, chainId, mint, transactionHash } =
    React.useContext(ContractContext);

  function validValueClosestTo(desiredNumericValue) {
    return String(Math.min(max, Math.max(desiredNumericValue, min)));
  }

  let mintBtn;
  if (MINTING_STARTED == false) {
    mintBtn = <></>;
  } else if (chainId && chainId == NETWORK_ID) {
    mintBtn = (
      <button
        className="btn purple-button"
        onClick={() => {
          mint(count);
          if (walletInfo?.url?.includes("trustwallet")) {
            handleShow();
            setHeading("Mint Request Sent To Mobile");
            setBody(
              "Please check your mobile phone to complete the mint purchase",
            );
          }
        }}
      >
        MINT NFT (BUY)
      </button>
    );
  } else if (chainId && chainId !== NETWORK_ID) {
    mintBtn = (
      <button className="btn purple-button">
        Switch Your Network To {NETWORK_NAME}
      </button>
    );
  } else if (
    typeof account === "undefined" &&
    typeof window?.ethereum !== "undefined"
  ) {
    mintBtn = (
      <div style={{ marginTop: "40px" }}>
        <Account purple />
      </div>
    );
  } else {
    mintBtn = (
      <div style={{ marginTop: "40px" }}>
        <Account purple />
      </div>
    );
  }

  function integerReducer(state, action) {
    const integerValue = parseInt(state.value, 10);
    switch (action.type) {
      case useStepper.actionTypes.increment: {
        return { value: validValueClosestTo(integerValue + 1) };
      }
      case useStepper.actionTypes.decrement: {
        return { value: validValueClosestTo(integerValue - 1) };
      }
      case useStepper.actionTypes.coerce: {
        if (Number.isNaN(integerValue)) {
          return { value: String(defaultValue) };
        }
        const newValue = validValueClosestTo(integerValue);
        if (newValue !== state.value) {
          return { value: newValue };
        }
        return state;
      }
      default:
        return useStepper.defaultReducer(state, action);
    }
  }

  useEffect(() => {
    fetch("https://pugz-be.vercel.app/api/summary")
      .then((res) => res.json())
      .then(
        (result) => {
          setShak(result.availableShak);
          setMinted(10000 - result.totalSupply);
        },
        (error) => {
          console.log(error);
          setShak(10000 - 1);
        },
      );
  }, []);

  const {
    getInputProps,
    getIncrementProps,
    getDecrementProps,
    value: count,
  } = useStepper({
    min,
    max,
    defaultValue,
    stateReducer: integerReducer,
  });

  return (
    <div className="giveaway-section" name="buy-squodge" id="buy-squodge">
      <div className="sl-slider">
        <Slider />
      </div>
      <div className="container">
        <SectionHeader text="Up to 75 Million SHAK Giveaway!" />
        <div className="block-content">
          <p className="description">The SHAK Vaults have been located...</p>
          <p className="description">
            Whispers of the fortunes that lay inside have attracted an army of
            PUGZ to try to break in and raid the SHAK Vault!
          </p>
          <p className="description">
            You will need skill, luck and a clever disguise to pull off this
            heist. Will you manage to raid the SHAK Vault and win MILLIONS of
            $SHAK tokens?
          </p>

          {MINTING_STARTED && (
            <>
              <SectionHeader
                fontSize="2xl"
                text={`${totalMinted || "0"} / 10000 PUGZ Remaining`}
              />
              <SectionHeader
                fontSize="2xl"
                text={`There are still ${totalShak || "10000"} "SHAK Mask" PUGZ
            Remaining!`}
              />
            </>
          )}

          {/*
          <ProgressBar progress={(totalMinted / 10000) * 100} /> */}
          {chainId == NETWORK_ID && MINTING_STARTED == true && (
            <>
              <p>How many PUGZ would you like to buy?</p>

              <div>
                <div className="stepper">
                  <button
                    {...getDecrementProps()}
                    className="btn purple-button no-margin"
                  >
                    -
                  </button>
                  <input
                    {...getInputProps()}
                    style={{
                      height: "100%",
                      width: 80,
                      color: "rgba(4, 13, 24)",
                      fontSize: 40,
                      textAlign: "center",
                      userSelect: "none",
                      background: "transparent",
                      cursor: "pointer",
                      border: "none",
                      borderWidth: 0,
                      fontWeight: 700,
                    }}
                    readOnly
                  />

                  <button
                    className="btn purple-button no-margin"
                    {...getIncrementProps()}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="input-block">
                <p>
                  Total cost: {(count * MINT_PRICE_IN_ETH).toFixed(3)} BNB + Gas
                  fee.
                </p>
              </div>
            </>
          )}
          <div className="buttons-block">
            <div className="buttons-wrapper">
              {transactionHash ? (
                <div style={{ maxWidth: "100%", wordBreak: "break-word" }}>
                  Mint successful! See your transaction here&nbsp;-
                  <span>
                    <a
                      href={`https://bscscan.com/tx/${transactionHash}`}
                      style={{ textDecoration: "underline" }}
                      target="_blank"
                      rel="noopener, noreferrer"
                    >
                      https://bscscan.com/tx/{transactionHash}
                    </a>
                  </span>
                </div>
              ) : (
                <></>
              )}
              {mintBtn}
            </div>
          </div>
          <div style={{ marginTop: "40px" }}>
            <SectionHeader text="100 days of SHAK PUGZ Raffles!" />

            <p className="description">
              From May 9th we will randomly select one minted SHAK PUGZ NFT
              every single day for the next 100 days. Each day the owner of the
              selected NFT will be sent 100k SHAK tokens!{" "}
            </p>
            <p className="description">
              This raffle is in addition to the Tesla and the 75m SHAK token
              prizes upon sell out. Crypto Jesus has also added 5x $5,000 prizes
              to the mix upon sell out of this collection too.
            </p>
            <p className="description">
              <a
                href="https://x.com/cryptojesus666/status/1793382445837877649?s=46&t=WgUV3SWstMv7WhRSWQfhAw"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline" }}
              >
                https://x.com/cryptojesus666/status/1793382445837877649?s=46&t=WgUV3SWstMv7WhRSWQfhAw
              </a>
            </p>
            <p className="description">
              Stay tuned in to the SHAK PUGZ Twitter page too as there are
              something other competitions and giveaways going on for PUGZ
              holders!
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <a
                href="https://x.com/shakpugznft?s=21&t=WgUV3SWstMv7WhRSWQfhAw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="btn connect-button"
                  style={{
                    margin: 0,
                  }}
                >
                  SHAK PUGZ Twitter
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{body}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
