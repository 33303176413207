import photo1 from "../assets/images/photos/Background_ANCIENT_GARDEN_sword_no_sword_fur_zebra_ear_none_eyes.jpg";
import photo2 from "../assets/images/photos/Background_BAR_sword_no_sword_fur_robot_ear_none_eyes_open_eyes.jpg";
import photo4 from "../assets/images/photos/Background_DOJO_sword_no_sword_fur_original_pug_color_ear_none_eyes.jpg";
import photo5 from "../assets/images/photos/Background_Pink_Back_MoneyBag_Fur_Acid_Ear_None_Eyes_Blindfold_Clothes.jpg";
import photo6 from "../assets/images/photos/Background_DOJO_sword_no_sword_fur_original_pug_color_ear_none_eyes (1).jpg";
import photo7 from "../assets/images/photos/Background_Redsky_Back_MoneyBag_Fur_Green_Ear_None_Eyes_Openeyes.jpg";
import photo8 from "../assets/images/photos/Background_YELLOW_sword_no_sword_fur_robot_ear_none_eyes_eyepatch.jpg";
import photo9 from "../assets/images/photos/IMG_5763.JPG";
import photo10 from "../assets/images/photos/IMG_5764.JPG";
import photo11 from "../assets/images/photos/IMG_5778.JPG";
import photo12 from "../assets/images/photos/IMG_5782.JPG";
import photo13 from "../assets/images/photos/IMG_5783.JPG";
import photo14 from "../assets/images/photos/IMG_5784.JPG";
import photo15 from "../assets/images/photos/IMG_5787.JPG";
import photo16 from "../assets/images/photos/IMG_5798.JPG";

export const IMAGES = [
  photo1,
  photo2,
  photo14,
  photo4,
  photo16,
  photo5,
  photo6,
  photo7,
  photo8,
  photo11,
  photo12,
  photo9,
  photo13,
  photo10,
  photo15,
];
