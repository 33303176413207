import { Stack, Text } from "@chakra-ui/react";
import React from "react";
import SectionHeader from "../SectionHeader";
import vaultImage from "../../assets/images/libvaultlogo.png";

export const Competition = () => {
  return (
    <>
      <Stack
        w="full"
        id="competition"
        py={{ base: 8, md: 16 }}
        px={{ base: 4, md: 28 }}
        align="center"
      >
        <SectionHeader text="The Raid Rules" />

        <Stack w={{ base: "100%", md: "70%" }} spacing={8} align="center">
          <Text>
            There are 10,000 uniquely generated PUGZ. All of them have a chance
            to scoop the loot but the 3251 that wear a ‘SHAK MASK’ disguise will
            find it easier to get past the impenetrable layers of re-inforced
            steel and enter the vault!
          </Text>

          <Stack w="full" align="center" spacing={4} py={{ base: 2, md: 4 }}>
            <SectionHeader
              fontSize="2xl"
              text={`Up to 75 Million $SHAK in the Vault `}
            />
            <img src={vaultImage} alt="vault" className="vault-image" />
          </Stack>

          <SectionHeader fontSize="2xl" text={`Vault Raid Round 1:`} />

          <Text>Up to 25 Million $SHAK available in the round 1.</Text>
          <Text>
            A team of 5 PUGZ (4 SHAK masks and 1 non masked PUGZ) will be
            selected daily to raid the vault.
          </Text>
          <Text>
            1 to 5 Million $SHAK will be won and shared amongst the team equally
            until up to 25 million $SHAK has been raided.
          </Text>
          <SectionHeader fontSize="2xl" text={`Vault Raid Round 2:`} />

          <Text>
            Up to 50m SHAK available in round 2 + Up to 10m in the SHAKPOT.
          </Text>
          <Text>
            An elite team of 10 PUGZ will be selected to take home up to 40
            million that will be shared amongst them equally. 1 member from this
            team will also win up to an 10m SHAK tokens extra from the SHAKPOT
          </Text>
          <Text>The elite team will be made up of 10:</Text>
          <Text className="new-line">
            {`1 random NFT minter from the 1st day of launch\n
		1 random NFT minter from the sell out day\n
		1 who owns the golden framed PUGZ featuring Crypto Jesus\n
		1 person who owns more than 20 NFT's\n
		1 person who mints NFT #6666\n
		1 person who owns the most SHAK PUGZ NFT's\n
		1 randomly selected non masked PUGZ\n
		3 randomly selected SHAK MASKS\n  `}
          </Text>
          <Text>
            Didn't get a SHAK Mask? Don't worry, there will be opportunities to
            win SHAK Mask PUGZ in the SHAK community giveaways and competitions.
          </Text>
          <Text>
            See FAQ for more details including a full breakdown of the value of
            prizes up for grabs!
          </Text>
        </Stack>
      </Stack>
    </>
  );
};
