import React from "react";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import Gallery from "./GalleryApp";
import { extendTheme } from "@chakra-ui/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { createRoot } from "react-dom/client";
import { ContractProvider } from "./context/ContractContext";

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
    theme: "#250b28",
  },
};

const container = document.getElementById("root");
const root = createRoot(container);

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/gallery", element: <Gallery /> },
]);

const theme = extendTheme({ colors });

root.render(
  <React.StrictMode>
    <ContractProvider>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </ContractProvider>
  </React.StrictMode>,
);
