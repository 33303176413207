import React, { useEffect } from "react";
import "../Main/GiveAwaySection.css";
import "./Gallery.css";
import { Element } from "react-scroll";
import { AccountV2 } from "../Account";
import { ContractContext } from "../../context/ContractContext";
import { NETWORK_NAME, MINTING_STARTED, NETWORK_ID } from "../../consts";

import SectionHeader from "../SectionHeader";
import { Row, Container } from "react-bootstrap";
import { Center } from "@chakra-ui/layout";

export const GallerySection = () => {
  const [indexes, setCount] = React.useState([]);
  const { account, chainId } = React.useContext(ContractContext);

  let mintBtn;
  let isLoggedIn = false;
  if (MINTING_STARTED === false) {
    mintBtn = <></>;
  } else if (chainId && chainId === NETWORK_ID) {
    if (indexes.hasOwnProperty("resp")) {
      if (indexes["resp"].length === 0) {
        mintBtn = (
          <button className="btn purple-button">
            YOU DONT OWN SHAK PUGZ IN THIS WALLET ADDRESS
          </button>
        );
      }
    } else {
      mintBtn = <button className="btn purple-button">Fetching ...</button>;
    }
    isLoggedIn = true;
  } else if (chainId && chainId !== NETWORK_ID) {
    mintBtn = (
      <button className="btn purple-button">
        Switch Your Network To {NETWORK_NAME}
      </button>
    );
  } else if (
    typeof account === "undefined" &&
    typeof window?.ethereum !== "undefined"
  ) {
    mintBtn = (
      <div style={{ marginTop: "40px" }}>
        <AccountV2 />
      </div>
    );
  } else {
    mintBtn = (
      <div style={{ marginTop: "40px" }}>
        <AccountV2 />
      </div>
    );
  }

  useEffect(() => {
    fetch("https://pugz-be.vercel.app/api/balance/" + account)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        setCount(json);
      });
  }, [account]);

  var rows = [];
  if (indexes.hasOwnProperty("resp")) {
    for (var i = 0; i < indexes["resp"].length; i++) {
      rows.push(
        <div>
          <img src={indexes["images"][i]} alt="" />
          PUGZ #{indexes["resp"][i]}
        </div>,
      );
    }
  }

  let section;
  if (indexes.hasOwnProperty("resp")) {
    if (indexes["resp"].length > 0) {
      section = (
        <SectionHeader
          text={`You own ${indexes["resp"].length} PUGZ in your wallet`}
        />
      );
    } else {
      <></>;
    }
  }

  return (
    <Element className="giveaway-section" name="buy-squodge" id="buy-squodge">
      <div>
        {isLoggedIn && (
          <div className="gallery">
            <Container>
              {section}
              <Center>{mintBtn}</Center>

              <Row>{rows}</Row>
            </Container>
          </div>
        )}
        {!isLoggedIn && (
          <div className="buttons-block">
            <SectionHeader text="Connect Your Wallet To Check Your Assets" />
            <div className="buttons-wrapper">
              <AccountV2 />
            </div>
          </div>
        )}
      </div>
    </Element>
  );
};
