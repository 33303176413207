import React from "react";
import { Element } from "react-scroll";
import SectionHeader from "../SectionHeader";
import "./About.css";
import competitionImage from "../../assets/images/IMG_5763.JPG";

export const About = () => {
  return (
    <Element
      className="about-block container new-line'"
      name="about"
      id="story"
    >
      <SectionHeader fontSize="2xl" text={`Invasion of PUGZ!`} />

      <p>
        Don’t be fooled by their innocent faces - PUGZ is an organisation of
        highly skilled, expertly trained vault raiders who are professionals in
        pulling off heists.
      </p>
      <br />
      <p className="new-line">
        {`\nThe SHAK PUGZ NFT collection is made up of mugshots from highly classified files of every one of the 10,000 members that make up PUGZ crime mob. 
		Each one will attempt to enter and raid the SHAK Vault for its loot. Over 80 unique traits and talents 
		but only 1 trait will increase your chances to break into the Vault and raid the millions of $SHAK tokens that lie within...\n\n`}
      </p>
      <p>Do you have the SHAK Mask?</p>
      <img
        src={competitionImage}
        className="competition-image"
        alt="shak mask"
      />
    </Element>
  );
};
