import * as React from "react";
import { createContext } from "react";
import AppContract from "../assets/abi.json";
import { Contract, parseEther, BrowserProvider, getNumber } from "ethers";
import { CONTRACT_ADDRESS, MINT_PRICE_IN_ETH } from "../consts";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";

export const ContractContext = createContext(undefined);

export const ContractProvider = ({ children }) => {
  const [totalSupply, setTotalSupply] = React.useState(0);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  // handle logic to recognize the connector currently being activated
  const [instance, setInstance] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [transactionHash, setTransactionHash] = React.useState("");

  React.useEffect(() => {
    (async () => {
      if (address && walletProvider) {
        const provider = new BrowserProvider(walletProvider);

        await provider.send("eth_requestAccounts", []);

        const signer = await provider.getSigner();

        const contract = new Contract(CONTRACT_ADDRESS, AppContract, signer);

        contract
          .getFunction("totalSupply")
          .call(CONTRACT_ADDRESS)
          .then((supply) => {
            setTotalSupply(getNumber(supply));
          });
        setInstance(contract);
      }
    })();
  }, [address, walletProvider]);

  const mint = async (count) => {
    try {
      setLoading(true);
      if (!instance) {
        console.log("No instance");
        return;
      }
      if (!address)
        throw new Error(`No account selected. Try reauthenticating`);
      if (!count) throw new Error(`No token count provided.`);

      const value = parseEther((MINT_PRICE_IN_ETH * count).toFixed(3));

      const { hash } = await instance.mint(address, count, {
        value: value.toString(),
        gasLimit: (80000 + 150000 * count).toString(),
        chainId,
      });

      setLoading(false);
      setTransactionHash(hash);
    } catch (error) {
      console.log("Error Happened,");
      console.log(error);
      setLoading(false);
    }
  };

  const reset = () => {
    setTransactionHash("");
  };

  return (
    <ContractContext.Provider
      value={{
        active: isConnected,
        transactionHash,
        loading,
        mint,
        instance,
        reset,
        totalSupply,
        account: address,
        chainId,
        library: walletProvider,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};
