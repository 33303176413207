import React, { useState, useRef } from "react";
import "./Header.css";
import banner from "../../assets/images/banner.svg";
import burgerMenu from "../../assets/logos/burger-menu.svg";
import { useOutsideClick } from "../../hooks/useOutsideClick";

import { Link } from "react-scroll";
import { useWindowSize } from "../../hooks/useWindowSize";
import { AccountV2 as Account } from "../Account";

export const Header = () => {
  const [menuToggled, setMenuToggled] = useState(false);
  const headerRef = useRef(null);
  const { width } = useWindowSize();
  const offset = width < 991 ? -360 : width < 1130 ? -50 : 0;

  const toggleMenu = () => {
    setMenuToggled((prevState) => !prevState);
  };

  const closeToggleMenu = () => {
    setMenuToggled(() => false);
  };

  useOutsideClick(headerRef, closeToggleMenu);
  function isGalleryPage() {
    if (window.location.pathname === "/gallery") {
      return true;
    }
    return false;
  }
  const handleLinkClick = () => {
    if (isGalleryPage()) {
      window.location.href = "/";
    }
    closeToggleMenu();
  };

  return (
    <header ref={headerRef}>
      <div className="header-block">
        <div className="logo-block">
          <div className="logo-link">
            <img alt="banner" className="logo-banner" src={banner} />
          </div>
          <img
            alt="burger menu for menu expand"
            className="toggle-menu-icon"
            src={burgerMenu}
            onClick={toggleMenu}
          />
        </div>

        <nav className={`header-nav${menuToggled ? " visible" : ""}`}>
          <Link
            smooth={"easeInOutQuart"}
            className="nav-link"
            delay={100}
            offset={offset - 100}
            to="buy-squodge"
            //to="buy-pugz"
            onClick={handleLinkClick}
            // hashSpy
            spy
          >
            Mint/Buy
          </Link>
          <Link
            smooth={"easeInOutQuart"}
            className="nav-link"
            delay={100}
            offset={offset - 100}
            to="story"
            //to="buy-pugz"
            onClick={handleLinkClick}
            // hashSpy
            spy
          >
            Story
          </Link>
          <Link
            smooth={"easeInOutQuart"}
            className="nav-link"
            delay={100}
            offset={offset}
            to="competition"
            onClick={handleLinkClick}
            // hashSpy
            spy
          >
            Rules
          </Link>
          {/* <Link
            smooth={"easeInOutQuart"}
            className="nav-link"
            delay={100}
            to="roadmap"
            onClick={handleLinkClick}
            // hashSpy
            spy
          >
            Roadmap
          </Link>  */}

          <Link
            smooth={"easeInOutQuart"}
            className="nav-link"
            delay={100}
            offset={offset - 100}
            href="/gallery"
            //to="buy-pugz"
            onClick={(event) => (window.location.href = "/gallery")}
            // hashSpy
            spy
          >
            My Pugz
          </Link>
          <Link
            smooth={"easeInOutQuart"}
            className="nav-link"
            delay={100}
            offset={-50}
            to="faq"
            onClick={handleLinkClick}
            // hashSpy
            spy
          >
            FAQ
          </Link>
        </nav>
        <div className="button-group">
          <Account />
        </div>
      </div>
    </header>
  );
};
