import "./App.css";
import { Header } from "./components";
import { GallerySection } from "./components/Gallery";
function Gallery() {
  return (
    <div className="Gallery">
      <Header />
      <GallerySection />
    </div>
  );
}

export default Gallery;
