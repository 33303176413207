import React from "react";
import { About } from "./About";
import { Competition } from "./Competition";
import FAQSection from "./FAQSection";
import { GiveAwaySection } from "./GiveAwaySection";
import "./Main.css";

export const Main = () => {
  return (
    <main>
      <GiveAwaySection />
      <About />
      <Competition />

      <FAQSection />
    </main>
  );
};
