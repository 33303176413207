import "./App.css";
import { Header, Main } from "./components";
import { Footer } from "./components/Footer";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";

// 1. Your WalletConnect Cloud project ID
const projectId = "0d1d81f15b01cc0c87df195bdaa38a10";

// 2. Set chains
const bsc = {
  chainId: 56,
  name: "BSC",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed.binance.org/",
};

// 3. Create a metadata object
const metadata = {
  name: "shak-pugz",
  description: "Web3Modal Example",
  url: "https://web3modal.com", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [bsc],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function App() {
  return (
    <div className="App">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
