import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
  Text,
  Stack,
} from "@chakra-ui/react";
import SectionHeader from "../SectionHeader";
import React from "react";
/* var link =(<Stack w="full" textAlign="left" align="flex-start">
  <Text textAlign="left">
  The Library Vault is the competition arm of the Telegram chatroom <a href="https://t.me/TheLibraryofCrypto" className="library-link">
The Library
</a> - a chatroom for open discussions and shilling of upcoming and existing tokens. The Library Vault holds a huge amount of tokens across many projects and will regularly run competitions.
        </Text> */
// </Stack>)

const faqs = [
  {
    q: "Who are SHAK & PUGZ?",
    a: (
      <Stack w="full" textAlign="left" align="flex-start">
        <Text textAlign="left">
          SHAK aka Samurai Shak, BIG SHAK or The SHIBKILLER is a popular dog
          coin character on Binance Smart Chain. This 10K NFT collection was
          specially created to celebrate SHAK reaching 10k hodlers. We wanted to
          reward the SHAK FAM and give them a chance to win huge prizes with the
          first ever official SHAK NFT Collection.
        </Text>

        <Text textAlign="left">
          PUGZ is a new character introduced to us by the SHAK team for this
          NFT. Instead of making a predictable collection we used our
          imagination. Pugz as you know is a collective after the loot inside
          the SHAK vault. Rumor has it these foes have history and this might
          not be the last we see of Pugz.
        </Text>
        <Text textAlign="left">
          Learn more about SHAK and the Shakita Inu project at
          <a
            href="http://www.shakitainu.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.shakitainu.com
          </a>
        </Text>
      </Stack>
    ),
  },

  {
    q: "How many NFT’s are there and how many are unique?",
    a: "There are 10,000 NFT’s and every single one of them is individually unique!",
  },
  {
    q: "How can I buy them and how much do they cost?",
    a: (
      <Stack w="full" textAlign="left" align="flex-start">
        <Text textAlign="left">
          You can buy your PUGZ NFT by using either metamask or trust wallet.
        </Text>
        <Text textAlign="left">Step 1) Click “Connect Wallet” </Text>
        <Text textAlign="left">
          Step 2) Select Metamask if on desktop (make sure you are on Google
          Chrome and have already downloaded the Metamask Extension){" "}
        </Text>
        <Text textAlign="left">
          or if you are using a mobile phone then after “Connect Wallet” choose
          > Wallet Connect -> Metamask or Trust wallet{" "}
        </Text>
        <Text textAlign="left">
          Step 3) Allow SHAK PUGZ NFT site permission to connect from your
          wallet provider (Metamask or Trust Wallet){" "}
        </Text>
        <Text textAlign="left">
          Step 4) Go back to the shakpugz.com and select how menu how many PUGZ
          you want to mint (buy){" "}
        </Text>
        <Text textAlign="left">
          Congratulations you have bought your first SHAK PUGZ NFT!
        </Text>
        <Text textAlign="left">Each PUGZ will cost 0.05 BNB</Text>
        <Text textAlign="left">
          See links at the bottom of the page for any assistance.{" "}
        </Text>
      </Stack>
    ),
  },
  {
    q: "How can I see the NFT that I minted?",
    a: (
      <Stack w="full" textAlign="left" align="flex-start">
        <Text textAlign="left">
          There are 3 ways to see the NFTS you have minted: <br /> 1) On this
          website <br />
          Connect your wallet to the this site and use the dashboard we created
          to see the PUGZ you minted with any of your wallets.
        </Text>

        <Text textAlign="left">
          2) In your wallet <br /> You can see the NFT in your Metamask wallet
          app on your phone by clicking ‘NFTs’ tab and then clicking Import NFT.
          Then you cop & pasting the SHAK PUGZ NFT contract address which is
          (will give you later) and add the NFT ID. The ID is unique to each
          PUGZ and it is basically the mint number that can be found in the TX
          hash data on{" "}
          <a
            href="https://bscscan.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            bscscan.com
          </a>
          . For assistance join the SHAK Telegram or Discord.
        </Text>
        <Text textAlign="left">
          3) On a secondary market place
          <br /> We have partnered with{" "}
          <a
            href="https://www.openbisea.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.openbisea.io
          </a>{" "}
          to allow you to see the NFT on their site in ‘My Collectibles’. Simple
          connect to their website and you will also be able to auction you NFT
          if you like. <br /> <br /> In the future there may be more
          marketplaces including one this website.
        </Text>
      </Stack>
    ),
  },

  {
    q: "Can I choose which one I get and where can I see my NFT after I buy?",
    a: (
      <Stack w="full" textAlign="left" align="flex-start">
        <Text textAlign="left">
          You will not be able to see which one you got until after you have
          minted it. They are all hidden and randomly ordered to give everyone
          an equal chance.
        </Text>
        <Stack w="full" textAlign="left" align="flex-start">
          <Text fontWeight="bold">
            After you have minted your NFT you can see it via 3 different ways;
          </Text>
          <Text>1) On this website</Text>
          <Text>2) On your mobile via metamask app</Text>
          <Text>3) On https://openbisea.io </Text>
        </Stack>
      </Stack>
    ),
  },
  {
    q: "Will they be on OpenSea or any other market place?",
    a: (
      <Text textAlign="left">
        Opensea only has Ethereum and Polygon NFT’s. Because the SHAK PUGZ NFT
        Collection is on Binance Smart Chain you will be able to use OpenBiSea
        instead -{" "}
        <a
          href="https://openbisea.io"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://openbisea.io
        </a>
        . <br /> If OpenSea adds Binance Smart Chain NFTs we will also be listed
        on there.,
      </Text>
    ),
  },
  {
    q: "When will we get a chance to raid the vault for prizes?",
    a: "As soon as the collection has sold out and all SHAK MASKS have been minted the fun will begin!",
  },
  {
    q: "How much will the $SHAK tokens be worth?",
    a: (
      <Stack w="full" textAlign="left" align="flex-start">
        <Text textAlign="left">
          The value of $SHAK tokens will change because it is a live trading
          cryptocurrency and the price is constantly changing.
        </Text>
        <Text textAlign="left">
          In round 1 there are up to 25m tokens. The value of the giveaway will
          be capped at $125,000 total and $25,000 per day.
        </Text>
        <Text textAlign="left">
          In round 2 there are up to 40m tokens shared equally amongst 10 vault
          raiders and the total value will be capped at $200,000.
        </Text>
        <Text textAlign="left">
          The SHAKPOT will have up to 10m tokens for one lucky individual. The
          total value will be capped at $50,000.
        </Text>
      </Stack>
    ),
  },
  {
    q: "What is the NFT contract address and ID number?",
    a: (
      <Stack w="full" textAlign="left" align="flex-start">
        <Text textAlign="left">
          Here is the contract address for SHAK PUGZ NFT:
        </Text>
        <Text textAlign="left" fontWeight="bold" fontSize="sm">
          0x1ffBE63cDAa0421e39D1D849cd8a14fC440feA65
        </Text>
        <Text textAlign="left">
          And your ID number can be found in your TX hash or a quicker way is to
          connect to the ‘My Pugz’ page and find the number of each NFT you own
          there.
        </Text>
        <Text textAlign="left">
          You can then import them into your Metamask app wallet. They should
          automatically show up on Trust Wallet though.
        </Text>
      </Stack>
    ),
  },
];

export default function FAQSection() {
  return (
    <>
      <Flex
        justifyContent="center"
        width="100%"
        py={{ base: 8, md: 16 }}
        // color="white"
        px={{ base: 2, md: 0 }}
        fontFamily="Inconsolata"
        id="faq"
      >
        <Stack w="full" align="center">
          <SectionHeader text="FAQ" />

          <Accordion
            allowToggle
            width={{ base: "100%", md: "60%" }}
            mb="3"
            borderBottomColor="brand.theme"
            borderBottomWidth="3px"
            borderBottomStyle="solid"
          >
            {faqs.map((faq, idx) => (
              <AccordionItem
                p={2}
                borderColor="brand.theme"
                borderWidth="3px"
                borderStyle="solid"
                borderTop={idx === 0 ? "" : "none"}
              >
                <h2>
                  <AccordionButton>
                    <Box
                      flex="1"
                      textAlign="left"
                      fontWeight="bold"
                      fontSize="2xl"
                    >
                      {faq.q}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontSize="xl" textAlign="left" fontWeight="300">
                  {faq.a}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Stack>
      </Flex>
      <Stack w="full" spacing={2} py={{ base: 8, md: 16 }}>
        <SectionHeader text={`Rarity`} />

        <p>You can access the rarity document below!</p>
        <div className="buttons-block">
          <button
            className="btn purple-button"
            onClick={() => alert("Coming soon!")}
          >
            Rarity DOC
          </button>
        </div>
      </Stack>
    </>
  );
}
